import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BetaInvite = makeShortcode("BetaInvite");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Harbor Beta`}</h1>
    <BetaInvite mdxType="BetaInvite" />
    <h2>{`1.0 (22)`}</h2>
    <h3>{`What’s new`}</h3>
    <ul>
      <li parentName="ul">{`New Explore page for discovering new RSS feeds`}</li>
      <li parentName="ul">{`Improved, multi-result search`}</li>
      <li parentName="ul">{`Added a button for viewing an article’s source webpage`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "59.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/84a5e061399f513ac2623be60e93e721/e93cc/1.0_22.webp 300w", "/static/84a5e061399f513ac2623be60e93e721/b0544/1.0_22.webp 600w", "/static/84a5e061399f513ac2623be60e93e721/68fc1/1.0_22.webp 1200w", "/static/84a5e061399f513ac2623be60e93e721/a2303/1.0_22.webp 1800w", "/static/84a5e061399f513ac2623be60e93e721/4293a/1.0_22.webp 2400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/84a5e061399f513ac2623be60e93e721/eed55/1.0_22.png 300w", "/static/84a5e061399f513ac2623be60e93e721/7491f/1.0_22.png 600w", "/static/84a5e061399f513ac2623be60e93e721/8537d/1.0_22.png 1200w", "/static/84a5e061399f513ac2623be60e93e721/d2cc9/1.0_22.png 1800w", "/static/84a5e061399f513ac2623be60e93e721/00711/1.0_22.png 2400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/84a5e061399f513ac2623be60e93e721/8537d/1.0_22.png",
            "alt": "Harbor beta 1.0 (22) example screenshots.",
            "title": "Harbor beta 1.0 (22) example screenshots.",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2>{`1.0 (16)`}</h2>
    <h3>{`What’s new`}</h3>
    <ul>
      <li parentName="ul">{`A more readable timeline — ‘casual’ feeds will be collapsed after 3 posts, and ‘completionist’ feeds after 10.`}<br />{`
This helps prevent frequently-published feeds from overwhelming your timeline.`}<br />{`A new indicator allows you to see all the posts from a collapsed feed.`}</li>
      <li parentName="ul">{`Manage your existing subscriptions from the Settings page`}</li>
      <li parentName="ul">{`Feed icons ✨`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "59.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a9d7f328068bb8e55272a86835196041/e93cc/1.0_16.webp 300w", "/static/a9d7f328068bb8e55272a86835196041/b0544/1.0_16.webp 600w", "/static/a9d7f328068bb8e55272a86835196041/68fc1/1.0_16.webp 1200w", "/static/a9d7f328068bb8e55272a86835196041/a2303/1.0_16.webp 1800w", "/static/a9d7f328068bb8e55272a86835196041/4293a/1.0_16.webp 2400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a9d7f328068bb8e55272a86835196041/eed55/1.0_16.png 300w", "/static/a9d7f328068bb8e55272a86835196041/7491f/1.0_16.png 600w", "/static/a9d7f328068bb8e55272a86835196041/8537d/1.0_16.png 1200w", "/static/a9d7f328068bb8e55272a86835196041/d2cc9/1.0_16.png 1800w", "/static/a9d7f328068bb8e55272a86835196041/00711/1.0_16.png 2400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a9d7f328068bb8e55272a86835196041/8537d/1.0_16.png",
            "alt": "Harbor beta 1.0 (16) example screenshots.",
            "title": "Harbor beta 1.0 (16) example screenshots.",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2>{`1.0 (9)`}</h2>
    <p>{`Welcome to the pre-release beta of Harbor!`}</p>
    <h3>{`What’s here`}</h3>
    <ul>
      <li parentName="ul">{`Mostly functional single-timeline feed reader interface`}</li>
      <li parentName="ul">{`Fast, but rudimentary, feed search functionality`}</li>
      <li parentName="ul">{`Rudimentary iPad split view layout`}</li>
      <li parentName="ul">{`Local and iCloud-based sync for feed subscriptions with multi-device support`}</li>
    </ul>
    <h3>{`What’s missing`}</h3>
    <ul>
      <li parentName="ul">{`A real app icon; this one’s a placeholder`}</li>
      <li parentName="ul">{`Empty state illustrations`}</li>
      <li parentName="ul">{`Improved feed search & feed directory`}</li>
      <li parentName="ul">{`Improved UI for management of existing subscriptions`}</li>
      <li parentName="ul">{`Integration with web-based feed subscription services like Feedly (import only)`}</li>
      <li parentName="ul">{`More complete iPad interface support`}</li>
      <li parentName="ul">{`Mac support`}</li>
      <li parentName="ul">{`More robust distinction of ‘casual’ vs ‘completionist’ feeds, which may include collapsing older posts from casual feeds into a single timeline entry`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "59.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/16d6760347ce26dda9151c176bf00460/e93cc/1.0_9.webp 300w", "/static/16d6760347ce26dda9151c176bf00460/b0544/1.0_9.webp 600w", "/static/16d6760347ce26dda9151c176bf00460/68fc1/1.0_9.webp 1200w", "/static/16d6760347ce26dda9151c176bf00460/a2303/1.0_9.webp 1800w", "/static/16d6760347ce26dda9151c176bf00460/4293a/1.0_9.webp 2400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/16d6760347ce26dda9151c176bf00460/eed55/1.0_9.png 300w", "/static/16d6760347ce26dda9151c176bf00460/7491f/1.0_9.png 600w", "/static/16d6760347ce26dda9151c176bf00460/8537d/1.0_9.png 1200w", "/static/16d6760347ce26dda9151c176bf00460/d2cc9/1.0_9.png 1800w", "/static/16d6760347ce26dda9151c176bf00460/00711/1.0_9.png 2400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/16d6760347ce26dda9151c176bf00460/8537d/1.0_9.png",
            "alt": "Harbor beta 1.0 (9) example screenshots.",
            "title": "Harbor beta 1.0 (9) example screenshots.",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      